import React, { useState, useEffect } from "react";
import { Button, Col, Dropdown, DatePicker, message, Row, Space } from "antd";
import { Spinner, Chart } from "../components/common";
import { useGlobal } from "../lib/globalFunc";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDateWithISO, getPrevday, getNextday } from "../constant/func";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
import { MISSED_IPTV_TV_OPTOINS } from "../constant/options";

export const ChartPage = () => {
  const [dropdownValue, setDropdownValue] = useState(null);
  const [thisLoading, setThisLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [date, setDate] = useState(null);
  const [chartData, setChartData] = useState([]);

  const { devices } = useSelector((state) => state.devices);
  const { user } = useSelector((state) => state.user);
  const { cTheme } = useSelector((state) => state.cTheme);

  const {
    getDevicesById,
    getSettingsAndFillSettingId,
    getChartDataByIdAndDate,
    loading,
  } = useGlobal();
  const { t } = useTranslation();

  const getChartDataAndPutChartData = async (tvType, settingId, date) => {
    try {
      setThisLoading(true);
      const data = await getChartDataByIdAndDate(tvType, {
        id: settingId,
        date,
      });
      if (data) {
        const chartDataSource = data.map((dt) => {
          if (tvType !== "analog_settings") {
            return {
              pwr: dt.pwr,
              snr: dt.snr,
              ber: dt.ber,
              time: dt.time_dat,
            };
          } else {
            return {
              pwr: dt.pwr,
              snr: dt.snr,
              time: dt.time_dat,
            };
          }
        });
        setChartData(chartDataSource);
        const formattedDate = moment(date);
        setDate(formattedDate);
      }
    } catch (err) {
      message.error(t("badRequest"));
    } finally {
      setThisLoading(false);
    }
  };

  // Handle change event
  const handleDropdownClick = async ({ key }) => {
    const splitted = key.split("-");
    const value = `${splitted[0].toString()} ${splitted[1].toString()} ${splitted[2].toString()}`;
    setDropdownValue(value);
    if (date) {
      const formattedDate = getDateWithISO(date);
      await getChartDataAndPutChartData(
        splitted[1].toString(),
        splitted[2].toString(),
        formattedDate
      );
    }
  };

  const handleDatePickerChange = async (date) => {
    setDate(date);
    if (dropdownValue) {
      const settingId = dropdownValue.split(" ")[2].toString();
      if (date && settingId !== "") {
        const formattedDate = getDateWithISO(date);
        await getChartDataAndPutChartData(
          dropdownValue.split(" ")[1].toString(),
          settingId,
          formattedDate
        );
      }
    }
  };

  const handlePrev = async () => {
    debugger;
    const settingId = dropdownValue.split(" ")[2].toString();
    const tvType = dropdownValue.split(" ")[1].toString();
    if (date && settingId !== "") {
      const formattedDate = getPrevday(date);
      await getChartDataAndPutChartData(tvType, settingId, formattedDate);
    }
  };

  const handleNext = async () => {
    const settingId = dropdownValue.split(" ")[2].toString();
    const tvType = dropdownValue.split(" ")[1].toString();
    if (date && settingId !== "") {
      const formattedDate = getNextday(date);
      await getChartDataAndPutChartData(tvType, settingId, formattedDate);
    }
  };
  /////////////////////////////////////////////////

  useEffect(() => {
    const getTotalData = async () => {
      try {
        setThisLoading(true);
        if (devices.length > 0) {
          const initialItems = devices.map((device) => ({
            key: device.id,
            label: `${device.id} ${device.place}`,
            children: MISSED_IPTV_TV_OPTOINS,
          }));
          const items = await Promise.all(
            initialItems.map(async (initialItem) => {
              const tvs = await Promise.all(
                initialItem.children.map(async (tv) => {
                  const settings = await getSettingsAndFillSettingId(
                    tv.key,
                    initialItem.key
                  );
                  return {
                    key: `${initialItem.key} ${tv.key}`,
                    label: `${tv.label}`,
                    children: settings
                      .filter((setting) => setting.active === 1)
                      .map((setting) => ({
                        key: `${initialItem.key}-${tv.key}-${setting.id}`,
                        label:
                          tv.key === "analog_settings"
                            ? setting.program_name
                            : setting.name,
                      })),
                  };
                })
              );
              return {
                key: initialItem.key,
                label: initialItem.label,
                children: tvs,
              };
            })
          );
          setItems(items);
        }
      } catch (err) {
        message.error(t("badRequest"));
      } finally {
        setThisLoading(false);
      }
    };
    getTotalData();
  }, [devices]);

  useEffect(() => {
    const fetchAllSettings = async () => {
      if (user && user.id) {
        await getDevicesById(user.locations);
      }
    };
    fetchAllSettings();
  }, [user]);

  if (loading || thisLoading) {
    return <Spinner />;
  }

  return (
    <div style={{ padding: 20 }}>
      <Row gutter={16}>
        <Col span={3}>
          <Dropdown
            menu={{
              items,
              onClick: handleDropdownClick,
            }}
          >
            <Space
              style={{
                color: `${cTheme ? "white" : "black"}`,
                padding: "5px 10px",
                border: "1px solid #585858",
                borderRadius: "5px",
              }}
            >
              {dropdownValue ? dropdownValue : t("selectLocation")}
              <DownOutlined />
            </Space>
          </Dropdown>
        </Col>
        <Col span={3}>
          <DatePicker
            style={{ display: "block" }}
            onChange={handleDatePickerChange}
            value={date}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 30 }}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ marginRight: 100 }}
              type="link"
              onClick={handlePrev}
              disabled={!date || !dropdownValue}
            >
              {t("prev")}
            </Button>
            <Button
              type="primary"
              onClick={handleNext}
              disabled={!date || !dropdownValue}
            >
              {t("next")}
            </Button>
          </div>
          <div style={{ marginTop: 30 }}>
            <Chart data={chartData} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
