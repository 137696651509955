import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";

export const LanguageSwitcher = () => {
  const [seleted, setSelected] = useState("EN");
  const { i18n } = useTranslation();

  const handleMenuClick = (e) => {
    if (e.key === "en") setSelected("EN");
    else setSelected("UA");
    i18n.changeLanguage(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="en"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        EN{" "}
        <img
          src="./en.svg"
          style={{ marginLeft: 10, width: 20, height: "auto" }}
        />
      </Menu.Item>
      <Menu.Item
        key="ua"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        UA{" "}
        <img
          src="./ua.svg"
          style={{ marginLeft: 10, width: 20, height: "auto" }}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a
        className="ant-dropdown-link"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        }}
        onClick={(e) => e.preventDefault()}
      >
        {seleted === "EN" ? (
          <>
            <span>EN</span>
            <img
              src="./en.svg"
              style={{ marginLeft: 5, width: 20, height: "auto" }}
            />
          </>
        ) : (
          <>
            <span>UA</span>
            <img
              src="./ua.svg"
              style={{ marginLeft: 5, width: 20, height: "auto" }}
            />
          </>
        )}
        <DownOutlined style={{ marginLeft: 15 }} />
      </a>
    </Dropdown>
  );
};
