export const NORMAL_TV_OPTIONS = [
  { key: "analog_settings", label: "Analog" },
  { key: "iptv_settings", label: "IPTV" },
  { key: "t2_settings", label: "DVB-T2" },
  { key: "cable_settings", label: "DVB-C" },
];

export const TABLE_TV_OPTIONS = [
  { key: "t2_settings", label: "DVB-T2" },
  { key: "cable_settings", label: "DVB-C" },
];

export const MISSED_IPTV_TV_OPTOINS = [
  { key: "analog_settings", label: "Analog" },
  { key: "t2_settings", label: "DVB-T2" },
  { key: "cable_settings", label: "DVB-C" },
];
