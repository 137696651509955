import React from "react";
import { Input } from "antd";
import { useSelector } from "react-redux";

export const InputField = ({
  name,
  placeholder,
  value,
  onChange,
  tooltip,
  disabled,
  type,
  isInvalid,
}) => {
  const { cTheme } = useSelector((state) => state.cTheme);

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ marginBottom: 5 }}>
        <label
          style={{ fontSize: "1em", color: `${cTheme ? "white" : "black"}` }}
        >
          {placeholder}
        </label>
      </div>
      <Input
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        type={type ? type : "text"}
        status={isInvalid ? "error" : ""}
      />
      {tooltip && (
        <div style={{ marginTop: 5 }}>
          <label style={{ fontSize: "0.9em", color: "#4db818" }}>
            {tooltip}
          </label>
        </div>
      )}
    </div>
  );
};
