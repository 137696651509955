import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Dropdown,
  Space,
  DatePicker,
  message,
  Button,
} from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "../components/common";
import { getDateWithISO, getNextday, getPrevday } from "../constant/func";
import { useGlobal } from "../lib/globalFunc";
import { DownOutlined } from "@ant-design/icons";
import { TABLE_TV_OPTIONS } from "../constant/options";

export const TablePage = () => {
  const [dropdownValue, setDropdownValue] = useState(null);
  const [thisLoading, setThisLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [date, setDate] = useState(null);
  const [groupedData, setGroupedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [lastTime, setLastTime] = useState(null);

  const { devices } = useSelector((state) => state.devices);
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const { cTheme } = useSelector((state) => state.cTheme);
  const {
    getDevicesById,
    getPmtsBySettingIdBeforeDate,
    getSettingsAndFillSettingId,
    loading,
  } = useGlobal();

  // Table Columns
  const columns = [
    {
      title: `Setting ID`,
      dataIndex: "setting_id",
    },
    {
      title: `Service name`,
      dataIndex: "service_name",
    },
    {
      title: `Service provider`,
      dataIndex: "service_provider",
    },
    {
      title: `Provider ID`,
      dataIndex: "provider_id",
    },
    {
      title: `${t("time")}`,
      dataIndex: "time",
    },
    {
      title: `Program number`,
      dataIndex: "program_number",
    },
    {
      title: `Channel ID`,
      dataIndex: "channel_id",
    },
    {
      title: "PMT PID",
      dataIndex: "PMT_PID",
    },
    {
      title: "PCR PID",
      dataIndex: "PCR_PID",
    },
    {
      title: "PCR PID",
      dataIndex: "PCR_PID",
    },
    {
      title: `Stream type 1`,
      dataIndex: "stream_type_1",
    },
    {
      title: `PID 1`,
      dataIndex: "PID_1",
    },
    {
      title: `Codec1 name`,
      dataIndex: "codec_name",
    },
    {
      title: `Width`,
      dataIndex: "width",
    },
    {
      title: `Height`,
      dataIndex: "height",
    },
    {
      title: `S ratio`,
      dataIndex: "s_ratio",
    },
    {
      title: `D ratio`,
      dataIndex: "d_ratio",
    },
    {
      title: `Stream type 2`,
      dataIndex: "stream_type_2",
    },
    {
      title: `PID 2`,
      dataIndex: "PID_2",
    },
    {
      title: `Codec2 name`,
      dataIndex: "codec2_name",
    },
    {
      title: `Sample rate`,
      dataIndex: "sample_rate",
    },
    {
      title: `Channels`,
      dataIndex: "channels",
    },
    {
      title: `Stream type 3`,
      dataIndex: "stream_type_3",
    },
    {
      title: `PID 3`,
      dataIndex: "PID_3",
    },
    {
      title: `Stream type 4`,
      dataIndex: "stream_type_4",
    },
    {
      title: `PID 4`,
      dataIndex: "PID_4",
    },
    {
      title: `Stream type 5`,
      dataIndex: "stream_type_5",
    },
    {
      title: `PID 5`,
      dataIndex: "PID_5",
    },
    {
      title: `Under control`,
      dataIndex: "under_control",
    },
  ];

  const fillTableData = (responseData, lt) => {
    const data = responseData.filter((dt) => dt.under_control === 1);
    const settingId = dropdownValue.split(" ")[2].toString();
    const groupedByDate = data.reduce((acc, item) => {
      const date = new Date(item.time).toISOString().split("T")[0];
      if (!acc[date]) acc[date] = [];
      acc[date].push({
        ...item,
        key: item.id,
        setting_id: settingId,
        time: new Date(item.time).toLocaleTimeString("en-GB"),
      });
      return acc;
    }, {});

    const groupedDataArray = Object.keys(groupedByDate).map((date) => ({
      date,
      records: groupedByDate[date],
    }));
    if (groupedDataArray.length > 0) {
      setLastTime(groupedDataArray[0].date);
    } else {
      setLastTime(lt);
    }
    setGroupedData(groupedDataArray);
    setCurrentPage(1);
  };

  // Handle change event
  const handleDatePickerChange = async (date) => {
    debugger;
    setDate(date);
    if (dropdownValue) {
      const settingId = dropdownValue.split(" ")[2].toString();
      const tvType = dropdownValue.split(" ")[1].toString();
      if (date && settingId !== "" && tvType !== "") {
        const formattedDate = getDateWithISO(date);
        const data = await getPmtsBySettingIdBeforeDate(
          tvType,
          settingId,
          formattedDate
        );
        if (data) {
          fillTableData(data);
        }
      }
    }
  };

  const handleDropdownClick = async ({ key }) => {
    const splitted = key.split("-");
    const value = `${splitted[0].toString()} ${splitted[1].toString()} ${splitted[2].toString()}`;
    setDropdownValue(value);
    if (date) {
      const settingId = dropdownValue.split(" ")[2].toString();
      const tvType = dropdownValue.split(" ")[1].toString();
      const formattedDate = getDateWithISO(date);
      const data = await getPmtsBySettingIdBeforeDate(
        tvType,
        settingId,
        formattedDate
      );
      if (data) {
        fillTableData(data, formattedDate);
      }
    }
  };

  const handlePrev = async () => {
    const settingId = dropdownValue.split(" ")[2].toString();
    const tvType = dropdownValue.split(" ")[1].toString();
    if (lastTime && settingId !== "") {
      const formattedDate = getPrevday(lastTime);
      const data = await getPmtsBySettingIdBeforeDate(
        tvType,
        settingId,
        formattedDate
      );
      if (data) {
        fillTableData(data, formattedDate);
      }
    }
  };

  const handleNext = async () => {
    const settingId = dropdownValue.split(" ")[2].toString();
    const tvType = dropdownValue.split(" ")[1].toString();
    if (lastTime && settingId !== "") {
      const formattedDate = getNextday(lastTime);
      const data = await getPmtsBySettingIdBeforeDate(
        tvType,
        settingId,
        formattedDate
      );
      if (data) {
        fillTableData(data, formattedDate);
      }
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //////////////////////////////////////////////

  // Hooks
  useEffect(() => {
    const getTotalData = async () => {
      try {
        setThisLoading(true);
        if (devices.length > 0) {
          const initialItems = devices.map((device) => ({
            key: device.id,
            label: `${device.id} ${device.place}`,
            children: TABLE_TV_OPTIONS,
          }));
          const items = await Promise.all(
            initialItems.map(async (initialItem) => {
              const tvs = await Promise.all(
                initialItem.children.map(async (tv) => {
                  const settings = await getSettingsAndFillSettingId(
                    tv.key,
                    initialItem.key
                  );
                  return {
                    key: `${initialItem.key} ${tv.key}`,
                    label: `${tv.label}`,
                    children: settings
                      .filter((setting) => setting.active === 1)
                      .map((setting) => ({
                        key: `${initialItem.key}-${tv.key}-${setting.id}`,
                        label:
                          tv.key === "analog_settings"
                            ? setting.program_name
                            : setting.name,
                      })),
                  };
                })
              );
              return {
                key: initialItem.key,
                label: initialItem.label,
                children: tvs,
              };
            })
          );
          setItems(items);
        }
      } catch (err) {
        message.error(t("badRequest"));
      } finally {
        setThisLoading(false);
      }
    };
    getTotalData();
  }, [devices]);

  useEffect(() => {
    const fetchAllSettings = async () => {
      if (user && user.id) {
        await getDevicesById(user.locations);
      }
    };
    fetchAllSettings();
  }, [user]);

  useEffect(() => {
    if (groupedData.length > 0) {
      setCurrentTableData(groupedData[currentPage - 1].records);
    } else {
      setCurrentTableData([]);
    }
  }, [groupedData]);

  if (loading || thisLoading) {
    return <Spinner />;
  }

  return (
    <div style={{ padding: 20 }}>
      <Row gutter={16}>
        <Col span={3}>
          <Dropdown
            menu={{
              items,
              onClick: handleDropdownClick,
            }}
          >
            <Space
              style={{
                color: `${cTheme ? "white" : "black"}`,
                padding: "5px 10px",
                border: "1px solid #585858",
                borderRadius: "5px",
              }}
            >
              {dropdownValue ? dropdownValue : t("selectLocation")}
              <DownOutlined />
            </Space>
          </Dropdown>
        </Col>
        <Col span={3}>
          <DatePicker
            style={{ display: "block" }}
            onChange={handleDatePickerChange}
            value={date}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            style={{ marginRight: 100 }}
            type="link"
            onClick={handlePrev}
            disabled={!date || !dropdownValue}
          >
            {t("prev")}
          </Button>
          <Button
            type="primary"
            onClick={handleNext}
            disabled={!date || !dropdownValue}
          >
            {t("next")}
          </Button>
        </div>
        <div style={{ width: "100%", marginTop: 20 }}>
          <h3
            style={{ color: "#1668dc", marginBottom: 10, textAlign: "right" }}
          >
            {lastTime || ""}
          </h3>
          <Table
            columns={columns}
            dataSource={currentTableData}
            scroll={{ x: 3000, y: 800 }}
            pagination={{
              current: currentPage,
              total: groupedData.length,
              onChange: handlePageChange,
              showSizeChanger: false,
            }}
          />
        </div>
      </Row>
    </div>
  );
};
