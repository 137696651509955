import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { theme } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "./store/store";
import "./custom-theme.less";
import { useAuth } from "./hooks/useAuth";
import "./i18n";

const AuthProviderWrapper = ({ children }) => {
  useAuth();
  return <>{children}</>;
};

const ConfigProviderWrapper = ({ children }) => {
  const { cTheme } = useSelector((state) => state.cTheme);

  return (
    <ConfigProvider
      theme={{
        algorithm: cTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorBgBase: cTheme ? "#161616" : "#FFFFFF",
          colorTextBase: cTheme ? "#FFFFFF" : "#000000",
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Provider store={store}>
      <ConfigProviderWrapper>
        <AuthProviderWrapper>
          <App />
        </AuthProviderWrapper>
      </ConfigProviderWrapper>
    </Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
