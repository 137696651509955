import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cTheme: true,
};

const themeSlice = createSlice({
  name: "cTheme",
  initialState,
  reducers: {
    setLight(state) {
      state.cTheme = false;
    },
    setDark(state) {
      state.cTheme = true;
    },
  },
});

export const { setLight, setDark } = themeSlice.actions;
export default themeSlice.reducer;
