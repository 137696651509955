import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  List,
  message,
  Row,
  Space,
  Tooltip,
} from "antd";
import {
  CustomModal,
  Mozaic,
  NumberField,
  Spinner,
} from "../components/common";
import { useTranslation } from "react-i18next";
import { useGlobal } from "../lib/globalFunc";
import { fetchAllGroups, updateExtVal } from "../lib/api";
import { getDateWithISO } from "../constant/func";
import { MISSED_IPTV_TV_OPTOINS } from "../constant/options";
import { DownOutlined } from "@ant-design/icons";

export const Main = () => {
  const [dropdownValue, setDropdownValue] = useState(null);
  const [thisLoading, setThisLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [date, setDate] = useState(null);
  const [modal, setModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [notifyInput, setNotifyInput] = useState({});
  const [mozaicData, setMozaicData] = useState([]);
  const [badDataGroup, setBadDataGroup] = useState({});
  const [compared, setCompared] = useState(false);
  const [groupData, setGroupData] = useState([]);

  const { devices } = useSelector((state) => state.devices);
  const { user } = useSelector((state) => state.user);
  const { cTheme } = useSelector((state) => state.cTheme);

  const { t } = useTranslation();
  const {
    getDevicesById,
    getSettingsAndFillSettingId,
    getAllSettings,
    getGroupByBadDataCnt,
    loading,
  } = useGlobal();

  // Interact with backend
  const getAllGroups = useCallback(async () => {
    try {
      const response = await fetchAllGroups();
      if (response.ok) {
        const { data } = response;
        const gd = data.map((dt) => `${dt.id} ${dt.name}`);
        setGroupData(gd);
      }
    } catch (err) {
      message.error(t("badRequest"));
    }
  }, []);

  const updateEXT = async (data) => {
    try {
      setConfirmLoading(false);
      const response = await updateExtVal(data);
      if (response.ok) {
        message.success(response.message);
        setNotifyInput({});
      }
    } catch (err) {
      message.error(t("badRequest"));
    } finally {
      setConfirmLoading(false);
    }
  };
  ////////////////////////////////////////////////////////////

  // Handle changes
  const handleNotifyInputChange = (name, value) => {
    if (value > 100) {
      message.info("The maximum value is 100");
      return;
    }
    setNotifyInput({ ...notifyInput, [name]: value });
  };

  const handleNotifyCheckboxChange = (e) =>
    setNotifyInput({ ...notifyInput, notify: e.target.checked });

  const handleDatePickerChange = async (date) => {
    setDate(date);
    if (date) {
      const formattedDate = getDateWithISO(date);
      const data = await getAllSettings({
        locations: user.locations,
        date: formattedDate,
      });
      if (data) {
        setMozaicData(data.setting);
        if (data.compareCnts.length > 0) {
          setCompared(true);
          const group = await getGroupByBadDataCnt(
            data.compareCnts[data.compareCnts.length - 1].cnt
          );
          if (group && group.id) {
            setBadDataGroup(group);
          }
        } else {
          setCompared(false);
        }
      }
    }
  };

  const handleDropdownClick = ({ key }) => {
    const splitted = key.split("-");
    const value = `${splitted[0].toString()} ${splitted[1].toString()} ${splitted[2].toString()}`;
    setDropdownValue(value);
  };

  const handleNotifySave = async () => {
    const { notify } = notifyInput;
    debugger;
    let data;
    const settingId = dropdownValue.split(" ")[2];
    const tvType = dropdownValue.split(" ")[1];
    if (settingId !== "" || !settingId) {
      data = {
        ...notifyInput,
        active: notify ? 1 : 0,
        settingsId: settingId,
        commandId:
          tvType === "analog_settings"
            ? 8
            : tvType === "cable_settings"
            ? 1
            : 4,
        userId: user.id,
      };
    } else {
      data = {
        ...notifyInput,
        active: notify ? 1 : 0,
        commandId:
          tvType === "analog_settings"
            ? 8
            : tvType === "cable_settings"
            ? 1
            : 4,
        userId: user.id,
      };
    }
    await updateEXT(data);
  };
  ////////////////////////////////////////////////////////////

  // Hooks
  useEffect(() => {
    const getTotalData = async () => {
      try {
        setThisLoading(true);
        let locations = [];
        if (user && user.id) {
          debugger;
          locations = await getDevicesById(user.locations);
          const data = await getAllSettings({
            locations: user.locations,
            date: new Date().toISOString().split("T")[0],
          });
          if (data) {
            setMozaicData(data.setting);
            if (data.compareCnts.length > 0) {
              setCompared(true);
              const group = await getGroupByBadDataCnt(
                data.compareCnts[data.compareCnts.length - 1].cnt
              );
              if (group && group.id) {
                setBadDataGroup(group);
              }
            } else {
              setCompared(false);
            }
          }
        }
        if (locations.length > 0) {
          const initialItems = locations.map((location) => ({
            key: location.id,
            label: `${location.id} ${location.place}`,
            children: MISSED_IPTV_TV_OPTOINS,
          }));
          const items = await Promise.all(
            initialItems.map(async (initialItem) => {
              const tvs = await Promise.all(
                initialItem.children.map(async (tv) => {
                  const settings = await getSettingsAndFillSettingId(
                    tv.key,
                    initialItem.key
                  );
                  return {
                    key: `${initialItem.key} ${tv.key}`,
                    label: `${tv.label}`,
                    children: settings
                      .filter((setting) => setting.active === 1)
                      .map((setting) => ({
                        key: `${initialItem.key}-${tv.key}-${setting.id}`,
                        label:
                          tv.key === "analog_settings"
                            ? setting.program_name
                            : setting.name,
                      })),
                  };
                })
              );
              return {
                key: initialItem.key,
                label: initialItem.label,
                children: tvs,
              };
            })
          );
          setItems(items);
        }
      } catch (err) {
        message.error(t("badRequest"));
      } finally {
        setThisLoading(false);
      }
    };
    getTotalData();
  }, [user]);

  // useEffect(() => {
  //   const fetchAllSettings = async () => {
  //     try {
  //       setThisLoading(true);
  //       if (user && user.id) {
  //         debugger;
  //         await getDevicesById(user.locations);
  //         const data = await getAllSettings({
  //           locations: user.locations,
  //           date: new Date().toISOString().split("T")[0],
  //         });
  //         if (data) {
  //           setMozaicData(data.setting);
  //           if (data.compareCnts.length > 0) {
  //             setCompared(true);
  //             const group = await getGroupByBadDataCnt(
  //               data.compareCnts[data.compareCnts.length - 1].cnt
  //             );
  //             if (group && group.id) {
  //               setBadDataGroup(group);
  //             }
  //           } else {
  //             setCompared(false);
  //           }
  //         }
  //       }
  //     } catch (err) {
  //       message.error("badRequest");
  //     } finally {
  //       setThisLoading(false);
  //     }
  //   };
  //   fetchAllSettings();
  // }, [user]);

  useEffect(() => {
    getAllGroups();
  }, [getAllGroups]);

  if (loading || thisLoading) {
    return <Spinner />;
  }

  return (
    <div style={{ padding: 20 }}>
      <Row gutter={16}>
        <Col span={2}>
          <Dropdown
            menu={{
              items,
              onClick: handleDropdownClick,
            }}
          >
            <Space
              style={{
                color: `${cTheme ? "white" : "black"}`,
                padding: "5px 10px",
                border: "1px solid #585858",
                borderRadius: "5px",
              }}
            >
              {dropdownValue ? dropdownValue : t("selectLocation")}
              <DownOutlined />
            </Space>
          </Dropdown>
        </Col>
        <Col span={1} style={{ marginLeft: 20 }}>
          <Tooltip placement="rightTop" title="Configuring notifications">
            <Button
              type="link"
              disabled={!dropdownValue}
              onClick={() => setModal(true)}
            >
              <img src="./notify.svg" alt="notify" />
            </Button>
          </Tooltip>
        </Col>
        <Col span={3}>
          <DatePicker
            style={{ display: "block" }}
            onChange={handleDatePickerChange}
            value={date}
          />
        </Col>
      </Row>
      {compared && (
        <Row
          gutter={16}
          style={{
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col span={6}>
            <Card
              title="Compare"
              style={{
                background: `${
                  badDataGroup.id ? "#c54d4d" : "rgb(58, 151, 87)"
                }`,
              }}
            >
              {badDataGroup.id && (
                <p
                  style={{
                    textAlign: "center",
                    color: `${cTheme ? "white" : "black"}`,
                  }}
                >{`${badDataGroup.id} ${badDataGroup.name}`}</p>
              )}
            </Card>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={20}>
          <Row gutter={16}>
            {mozaicData.length > 0 &&
              mozaicData.map((mozaic) => <Mozaic item={mozaic} />)}
          </Row>
        </Col>
        <Col span={4} style={{ marginTop: 20 }}>
          <List
            header={
              <div style={{ color: `${cTheme ? "white" : "black"}` }}>
                Groups
              </div>
            }
            bordered
            dataSource={groupData}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Col>
      </Row>
      <CustomModal
        open={modal}
        title={t("configNotify")}
        confirmLoading={confirmLoading}
        isSave={true}
        isDelete={true}
        handleOk={handleNotifySave}
        handleCancel={() => setModal(false)}
        handleDelete={() => setNotifyInput({})}
      >
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={12}>
            <NumberField
              name="pwrMin"
              value={notifyInput.pwrMin ? notifyInput.pwrMin : 0}
              onChange={(value) => handleNotifyInputChange("pwrMin", value)}
              placeholder="Pwr min"
            />
          </Col>
          <Col span={12}>
            <NumberField
              name="pwrMax"
              value={notifyInput.pwrMax ? notifyInput.pwrMax : 0}
              onChange={(value) => handleNotifyInputChange("pwrMax", value)}
              placeholder="Pwr max"
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={12}>
            <NumberField
              name="snrMin"
              value={notifyInput.snrMin ? notifyInput.snrMin : 0}
              onChange={(value) => handleNotifyInputChange("snrMin", value)}
              placeholder="Snr min"
            />
          </Col>
          <Col span={12}>
            <NumberField
              name="snrMax"
              value={notifyInput.snrMax ? notifyInput.snrMax : 0}
              onChange={(value) => handleNotifyInputChange("snrMax", value)}
              placeholder="Snr max"
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={12}>
            <NumberField
              name="berMin"
              value={notifyInput.berMin ? notifyInput.berMin : 0}
              onChange={(value) => handleNotifyInputChange("berMin", value)}
              placeholder="Ber min"
            />
          </Col>
          <Col span={12}>
            <NumberField
              name="berNax"
              value={notifyInput.berNax ? notifyInput.berNax : 0}
              onChange={(value) => handleNotifyInputChange("berNax", value)}
              placeholder="Ber max"
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={2} style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              name="notify"
              value={notifyInput.notify}
              onChange={handleNotifyCheckboxChange}
            />
            <label
              style={{ color: `${cTheme ? "white" : "black"}`, marginLeft: 10 }}
            >
              Notify
            </label>
          </Col>
        </Row>
      </CustomModal>
    </div>
  );
};
