import axiosInstance from "../axiosInstance";

export const fetchAllUsers = async () => {
  const response = await axiosInstance.get("/users/get/all");
  return response.data;
};

export const login = async (user) => {
  const response = await axiosInstance.post("/auth/user-login", user);
  return response.data;
};

export const usersRegister = async (user) => {
  const response = await axiosInstance.post("/auth/user-register", user);
  return response.data;
};

export const fetchUserById = async (id) => {
  const response = await axiosInstance.get(`/users/${id}`);
  return response.data;
};

export const updateUser = async (data) => {
  const response = await axiosInstance.patch("/users/update", data);
  return response.data;
};

export const deleteUser = async (data) => {
  const response = await axiosInstance.patch("/users/delete", data);
  return response.data;
};
