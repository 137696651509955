import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setUser } from "../store/slices/userSlice";
import { fetchUserById } from "../lib/api";
import { jwtDecode } from "jwt-decode";

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const token = localStorage.getItem("tv_monitor_token");
    const allowedPaths = ["/auth/login", "/auth/register"];
    const userPaths = ["/main", "/chart", "/table", "/video", "/compare"];
    const adminPaths = [
      "/devices",
      "/analog-setting",
      "/dvb-t2-setting",
      "/dvb-c-setting",
      "/iptv-setting",
      "/sequence",
      "/groups",
      "/schedules",
      "/users",
    ];

    if (!token && !allowedPaths.includes(location.pathname)) {
      navigate("/auth/login");
      return;
    }

    const handleUserRole = (currentUser) => {
      if (currentUser.role === "admin") {
        if (!adminPaths.includes(location.pathname)) {
          navigate("/devices");
        }
      } else {
        if (!userPaths.includes(location.pathname)) {
          navigate("/main");
        }
      }
    };

    if (token && typeof token === "string") {
      debugger;
      const decoded = jwtDecode(token);
      if (decoded.id && (!user || (typeof user === "object" && !user.id))) {
        const fetchUser = async (id) => {
          try {
            const response = await fetchUserById(id);
            if (response.ok) {
              const userData = response.data;
              dispatch(setUser(userData));
              handleUserRole(userData);
            }
          } catch (err) {
            message.error("Server error. Please try again.");
          }
        };

        fetchUser(decoded.id);
      } else if (user) {
        handleUserRole(user);
      }
    }
  }, [location]);
};
